<template>
  <vx-card>
    <div class="vx-row">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <div class="vx-col sm:w-1/1 w-full mb-base">
          <vs-tabs>
            <vs-tab label="AP Data">
              <div class="tab-text">
                <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Territory</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                      class="selectExample"
                      v-model="territory"
                      :options="optionTerr"
                      :multiple="true"
                      track-by="id"
                      :allow-empty="false"
                      :group-select="false"
                      :max-height="200"
                      :limit="5"
                      placeholder="Type to search"
                      :custom-label="customLabel"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Supplier</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                      class="selectExample"
                      v-model="supplier"
                      :options="optionSupp"
                      :multiple="true"
                      track-by="ID"
                      :allow-empty="false"
                      :group-select="false"
                      :max-height="200"
                      :limit="5"
                      placeholder="Type to search"
                      :custom-label="customLabel2"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Status</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                      class="selectExample"
                      v-model="statusSelected"
                      :options="optionStatus"
                      :multiple="true"
                      track-by="id"
                      :allow-empty="false"
                      :group-select="false"
                      :max-height="200"
                      :limit="5"
                      placeholder="Type to search"
                      :custom-label="customLabel"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Key Date</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <datepicker :inline="false" v-model="keyDate"></datepicker>
                  </div>
                </div>
                <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Posting Date</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <date-range-picker
                      style="min-height: 40px"
                      class="w-full"
                      ref="picker"
                      opens="center"
                      :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                      :singleDatePicker="false"
                      :timePicker="false"
                      :timePicker24Hour="false"
                      :showWeekNumbers="false"
                      :showDropdowns="false"
                      :autoApply="true"
                      v-model="filteDate"
                      :linkedCalendars="true"
                    >
                      <!-- <template v-slot:input="picker" style="min-width: 350px"> -->
                      <template v-slot:input="picker">
                        {{ dateFormat(picker.startDate) }} -
                        {{ dateFormat(picker.endDate) }}
                      </template>
                    </date-range-picker>
                  </div>
                </div>
                <!-- <div class="vx-row mb-6" style="width: 70%">
                  <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <vs-button color="success" @click="handleDrawTable()"
                      >Get Data</vs-button
                    >
                  </div>
                </div> -->
                <data-table
                  :baseUrl="this.baseUrl"
                  :detail="this.detail"
                  :territoryIDs="this.territoryIDs"
                  :supplierIDs="this.supplierIDs"
                  :status="this.status"
                  :keyDate="this.keyDate"
                  :startDate="this.filteDate.startDate"
                  :endDate="this.filteDate.endDate"
                  :draw="draw"
                ></data-table>
              </div>
            </vs-tab>
            <vs-tab label="Export">
              <div class="tab-text">
                <data-table-export></data-table-export>
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[detail ? detailShow : '', detailHide]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- <FormPlan @close="handleClose" :baseUrl="this.baseUrl"></FormPlan> -->
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DataTable from "./DataTable.vue";
import DataTableExport from "./DataTableExport.vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    DataTableExport,
    DataTable,
    DateRangePicker,
    Datepicker
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      baseUrl: "/api/v1/report/ap",
      draw: 0,
      territoryIDs: "0",
      supplierIDs: "0",
      status: "All",
      statusSelected: [{id: "'All'", code: "-", name: "All Status"}],
      keyDate: "",
      territory: [
        {
          id: 0,
          code: "",
          name: "",
        }
      ],
      optionTerr: [
        {
          id: 0,
          code: "",
          name: "",
        }
      ],
      supplier: [
        {
          ID: 0,
          Code: "",
          Name: "",
        }
      ],
      optionSupp: [
        {
          id: 0,
          code: "",
          name: "",
        }
      ],
      optionStatus: [
        {id: "'All'", code: "-", name: "All Status"},
        {id: "'Proposal Approved'", code: "-", name: "Proposal Approved"},
        {id: "'Paid'", code: "-", name: "Paid"},
        {id: "'Reversed'", code: "-", name: "Reversed"},
        {id: "'Waiting Payment'", code: "-", name: "Waiting Payment"},
      ],
      filteDate: {
        startDate: null,
        endDate: null
      },
    };
  },
  methods: {
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("MMM DD, YYYY");
      }
      return a;
    },
    customLabel(val) {
      if (val) {
        return val.code === "-" ? `${val.name}` :`(${val.code}) ${val.name}`;
      }
    },
    customLabel2(val) {
      if (val) {
        return val.Code === "-" ? `${val.Name}` :`(${val.Code}) ${val.Name}`;
      }
    },
    getOptionTerritory() {
      this.$http.get("/api/v1/master/territory").then(resp => {
        this.optionTerr = [{
            "id": 0,
            "code": "-",
            "name": "All Territory",
        }];
        this.optionTerr = [...this.optionTerr, ...resp.data.records];
        this.territory = [this.optionTerr[0]];
      });
    },
    getOptionSupplier() {
      this.$http.get("/api/v1/master/suppliers").then(resp => {
        this.optionSupp = [{
            "ID": 0,
            "Code": "-",
            "Name": "All Supplier",
        }];
        this.optionSupp = [...this.optionSupp, ...resp.data.records];
        this.supplier = [this.optionSupp[0]];
      });
    },
    handleClose() {
      this.detail = false;
    },
    handleOpen() {
      this.detail = true;
    },
    handleDrawTable() {
      this.draw++;
    }
  },
  watch: {
    territory: {
      handler(newVal, oldVal) {
        const ids = newVal.map(item => item.id);
        this.territoryIDs = ids.join(',');

        if (ids[0] == 0 && ids.length > 1) {
          this.territory.shift()
        } else if(ids.includes(0) && ids.length > 1) {
          this.territoryIDs = "0"
          this.territory = [{
            "id": 0,
            "code": "-",
            "name": "All Territory",
          }];
        }
      },
      deep: true
    },
    supplier: {
      handler(newVal, oldVal) {
        const ids = newVal.map(item => item.ID);
        this.supplierIDs = ids.join(',');

        if (ids[0] == 0 && ids.length > 1) {
          this.supplier.shift()
        } else if(ids.includes(0) && ids.length > 1) {
          this.supplierIDs = "0"
          this.supplier = [{
              "ID": 0,
              "Code": "-",
              "Name": "All Supplier",
          }];
        }
      },
      deep: true
    },
    statusSelected: {
      handler(newVal, oldVal) {
        const ids = newVal.map(item => item.id);
        this.status = ids.join(',');
        if (ids[0] == "'All'" && ids.length > 1) {
          this.statusSelected.shift()
        } else if(ids.includes("'All'") && ids.length > 1) {
          this.status = "'All'"
          this.statusSelected = [{id: "'All'", code: "-", name: "All Status"}]
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getOptionTerritory()
    this.getOptionSupplier()
    // const today = new Date();
    // this.filteDate.startDate = new Date(
    //   today.getFullYear(),
    //   today.getMonth() - 1,
    //   1
    // );
    // this.filteDate.endDate = new Date(today.getFullYear(), today.getMonth(), 0);
  }
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
